import React,{useEffect,useState}from 'react'
import { awaiting_Data } from "../Data/Questions/awaiting_Data";
import { ScrollRestoration, useLinkClickHandler, useLocation,useNavigate } from 'react-router-dom';
import { current_quiz } from '../Data/current_quiz.js';


function Exam() {
    const location = useLocation();
    const navigate = useNavigate();

    const examSize = location.state !== null ? location.state.amount : 5
    const examName = location.pathname.split('/exam/')[1]
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [quizScore, setQuizScore] = useState(0)

    const [questions,setQuestions]=useState(awaiting_Data)

    useEffect(()=>{
        initilize()
    },[])

    const initilize=()=>{
        setQuestions(current_quiz)
    }

    const handlePreviousQuestion=async ()=>{
        if (currentQuestion<=0)
            return
        setCurrentQuestion(currentQuestion-1)
    }
    const handleNextQuestion=async ()=>{
        if (currentQuestion>=questions.length)
            return
        setCurrentQuestion(currentQuestion+1)

        console.log(questions[currentQuestion+1].answer_correct)
    }
    const checkResult=()=>{
        var selectedAnwsers=[]
        var correctAnwsers=questions[currentQuestion].answer_correct
        var updatedQuestions = [...questions]; // Create a copy of the questions array


        questions[currentQuestion].answer_options.forEach(anwser=>{
            if(anwser.selected){
                selectedAnwsers.push(anwser.answer_ID)
            }
        })
        var score=1
        if(selectedAnwsers.length !== correctAnwsers.length){
            console.log("Lenght not mathcing")
            score=0
        }else{
            selectedAnwsers.forEach(sel=>{
                if(!correctAnwsers.includes(sel)){
                    console.log('WRONG')
                    score=0
                }
            })
        }
        if(score===1){
            setQuizScore(quizScore+1)
        }
        console.log("SCORE: ",score)
               
        //CHANGE HEADER BOXES & BLOCK CHANGES & calculate score
        updatedQuestions[currentQuestion].score=score

        updatedQuestions[currentQuestion].responded = true;
        setQuestions(updatedQuestions);   
    }


    const handleCheckboxChange=(event)=>{
        var isChecked=event.target.checked
        var answerIndex = event.target.value
        questions[currentQuestion].answer_options[answerIndex].selected=isChecked

        var updatedQuestions = [...questions]; // Create a copy of the questions array
        var updatedAnswers = [...updatedQuestions[currentQuestion].answer_options]; // Create a copy of the answer options array

        // Update the selected property of the answer option that was checked
        updatedAnswers[answerIndex].selected = isChecked;

        // Update the answer_options array in the current question object
        updatedQuestions[currentQuestion].answer_options = updatedAnswers;

        // Update the state with the new questions array
        setQuestions(updatedQuestions);   
    }

    const handleFinish=()=>{
        navigate(`/exam/${examName}/results`,{state:{'questions':questions,'score':quizScore}})
    }

    return (
        <>
            <div className='flex flex-col h-screen'>
                <div className="flex flex-col items-center justify-center h-40 bg-gray-100">
                    <h1 className="text-gray-800 font-bold text-4xl mb-2">{examName}</h1>
                    <p className="text-gray-800 font-bold text-2xl mr-4">{questions.length} Questions</p>
                    <p className="text-gray-800 font-bold text-2xl mr-4"> Score: {quizScore}/ {questions.length}</p>
                    <div className="flex flex-wrap justify-center">
                        {questions.map((question, index) => (
                            <button className={` hover:bg-gray-500 w-10 h-10 m-2 flex justify-center items-center rounded-lg
                            ${question.responded=== false & currentQuestion=== index ? 'bg-gray-500':'bg-gray-300'}
                            ${question.responded=== true & question.score === 1 ? 'bg-green-500':''}
                            ${question.responded=== true & question.score=== 0 ? 'bg-red-500':''}`}
                                onClick={()=>setCurrentQuestion(index)}> {index+1}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center h-3/5 p-5">
                    <div className="bg-gray-200 p-4 rounded-lg w-2/3 h-full">
                        <div className="bg-white rounded-lg shadow-lg p-6 h-full w-full relative">
                            <h2 className="text-xl font-bold mb-4">Question {currentQuestion+1} -- Chapter: {questions[currentQuestion].chapter_ID} - Question: {questions[currentQuestion].question_ID}</h2>
                            <p className="text-gray-700 mb-4">{questions[currentQuestion].question_text}</p>
                            <ul className="list-none pl-0">
                                {questions[currentQuestion].answer_options.map((res,index)=>(
                                    <li key={res.answer_ID}>
                                        <label className="inline-flex items-center mt-3">
                                            <input type="checkbox" className="form_checkboxes h-5 w-5 text-gray-600" checked={res.selected}  onChange={handleCheckboxChange} name="answer" value={index} disabled={questions[currentQuestion].responded} />
                                            <div>
                                                <span className={`ml-2
                                                    ${questions[currentQuestion].responded ?
                                                        res.selected ? questions[currentQuestion].answer_correct.includes(res.answer_ID) ? 'font-bold text-green-700':'font-bold text-red-700':
                                                        questions[currentQuestion].answer_correct.includes(res.answer_ID) ? 'text-red-700':'':'text-gray-700'}
                                                    `}>{res.answer_ID}: {res.answer_text}</span>
                                            </div>
                                        </label>
                                    </li>
                                ))}
                            </ul>
                            {questions[currentQuestion].responded ? (
                                <div className="px-4 py-2 bg-gray-200 m-4">
                                    <p className="text-gray-700 text-base mb-2">{questions[currentQuestion].explanation}</p>
                                </div>
                            ):(<></>)}
                            <div className="absolute bottom-0 left-0 w-full flex m-4 justify-between">
                                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-10"
                                    onClick={handlePreviousQuestion}> Previous
                                </button>
                                

                                <div className='flex justify-end'>
                                {questions[currentQuestion].responded ? (
                                    <>  
                                    {currentQuestion === questions.length - 1 ? (
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-10"
                                        onClick={handleFinish}> Finish
                                        </button>
                                        ) : (
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-10" 
                                        onClick={handleNextQuestion}> Next
                                        </button>
                                        )}
                                    </>
                                ):(    
                                    <> 
                                    <button
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-10"
                                        onClick={checkResult}> Submit Answer
                                    </button>
                                    {currentQuestion < questions.length - 1 ? (
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-10" 
                                        onClick={handleNextQuestion}> skip
                                        </button>
                                    ):""}
                                    </>
                                )}
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Exam;
