import Header from './Components/Header';
import ExamsQuestionOverView from './pages/ExamsQuestionOverView';
import React,{useEffect,useState}from 'react'
import { BrowserRouter, Route, Routes,Navigate } from 'react-router-dom';
import ExamSelection from './pages/ExamSelection';
import Login from './pages/Login';
import Exam from './pages/Exam';
import ExamResults from './pages/ExamResults';
import Results from './pages/Results';


function App() {
  const [authenticated, setAuthenticated]=useState(false)
  useEffect(()=>{
    const auth = sessionStorage.getItem("authenticated")
    if(auth){
      setAuthenticated(true)
    }
  },[])
  const handleLogin=()=>{
    setAuthenticated(true)
    sessionStorage.setItem("authenticated", true);
  }
  const handleLogout=()=>{
    setAuthenticated(false)
    sessionStorage.clear()
  }
  return (
    <BrowserRouter basename="/">
      {authenticated === true ? (
        <>
          <Header authenticated={authenticated} handleLogout={handleLogout}/>
          <Routes>
            <Route path="/" exact element={<ExamSelection/>} />
            <Route path="/examQuestions" element={<ExamsQuestionOverView/>} />
            <Route path="/exam/sap_fi" element={<Exam/>} />
            <Route path="/exam/sap_fi_cloud" element={<Exam/>} />
            <Route path="/exam/sap_fi/results" element={<ExamResults/>} />
            <Route path="/exam/sap_fi_cloud/results" element={<ExamResults/>} />
            <Route path="/results" element={<Results/>} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </>
      ):(<Login handleLogin={handleLogin}/>)}
    </BrowserRouter>
  );
}

export default App;
