export const SAP_FI=[
  {
    "chapter_ID": 1,
    "question_ID": 1,
    "question_text": "Which SAP technological products provide the platform required for SAP to support the digital transformation of an organization? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "SAP HANA"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "SAP Data Intelligence"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "SAP Fiori"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "SAP Business Technology Platform"
      }
    ],
    "answer_correct": [
      "A",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "SAP HANA is SAP's default platform to support the digital transformation forbusinesses. In addition, the SAP Business Technology Platform provides theinfrastructure and tools for developing next-generation applications."
  },
  {
    "chapter_ID": 1,
    "question_ID": 2,
    "question_text": "You want to develop a substantial enhancement for the purchase-to-pay process in your SAP S/4HANA system. Where does SAP recommend you perform this enhancement?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "SAP Analytics Cloud"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "SAP Fiori"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "SAP Business Technology Platform"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "SAP S/4HANA core"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "Traditionally, such enhancements are made directly in the core SAP ERP component.This will indeed still work today in your SAP S/4HANA system; however,SAP wants to shift such development to the SAP Business TechnologyPlatform. This is part of a strategy to keep the core running lean and as close tostandard as possible. The other answers are wrong because SAP Analytics Cloudis a product for planning and reporting that uses intelligent technologies; andSAP Fiori represents the UI level, so you would incorporate SAP Fiori as thedefault UI of your enhancement, but it is not where the enhancement is developed."
  },
  {
    "chapter_ID": 1,
    "question_ID": 3,
    "question_text": "True or False: SAP S/4HANA (on premise) can be deployed on a cloud infrastructure.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. There is no limitation on the location of the hardware that the on-premiseversion of SAP S/4HANA is deployed on. A customer can choose compatiblethird-party cloud vendors other than SAP. If a customer chooses to go with SAPas the cloud infrastructure vendor for an SAP S/4HANA on-premise deployment,the product is called SAP S/4HANA Cloud, private edition; with othervendor infrastructures, it is just SAP S/4HANA."
  },
  {
    "chapter_ID": 1,
    "question_ID": 4,
    "question_text": "The SAP HANA database relies on which of the following technologies for storing data? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "In-memory"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Aggregation"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Indexing"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Columnar store"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Compression"
      }
    ],
    "answer_correct": [
      "A",
      "D",
      "E"
    ],
    "score":0,
    "responded":false,
    "explanation": "In-memory is the most obvious answer here; columnar store is the preferredSAP HANA data storage technique; and through compression, SAP HANA managesto cut down on the infrastructure costs and support larger customers. Onthe other hand, aggregates are supported but should be avoided as the idea isto have all the data at the highest granularity to provide flexible reporting. Thesame is true for the indices because, with SAP HANA, they offer little speedimprovement and sacrifice too much in the way of flexibility as they must bepredefined, and adding reporting characteristics is difficult."
  },
  {
    "chapter_ID": 1,
    "question_ID": 5,
    "question_text": "Data in the SAP HANA database is stored in which two ways? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Random"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Vector"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Column"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Row"
      }
    ],
    "answer_correct": [
      "C",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "Column store is the preferred method for SAP HANA as discussed because it isbetter for reading data. Row storage is preferred by classic databases and is supportedfully by SAP HANA."
  },
  {
    "chapter_ID": 1,
    "question_ID": 6,
    "question_text": "True or False: In an environment of systems using SAP HANA, you can perform both OLAP and OLTP processing without duplication of the data for the SAP BW and SAP ERP systems.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. SAP HANA was designed to fulfill the goal of combining optimal OLTP andOLAP processing in one database. This simplifies the process of answeringquestions such as \u201cHow did I come up with this number?\u201d and saves on ITresources by requiring fewer systems to support."
  },
  {
    "chapter_ID": 1,
    "question_ID": 7,
    "question_text": "SAP HANA makes extensive use of which high-speed hardware technology to offer more speed?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Read-only memory"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Solid-state drives (SSDs)"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Graphics processing units (GPUs)"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Multicore CPUs"
      }
    ],
    "answer_correct": [
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "With many streams of data to process and large requirements for communicationbetween memory and the CPU cache, you can use a lot of CPU cores at thesame time. Even though GPUs might seem the most out of place here, theyoffer tremendous calculation power and are developing faster than CPUs.Although many companies are considering using some of their special characteristicsin the business application world, and we might be hearing of more ofthese exploits in the future, for now, they aren't used by SAP HANA. SSDs offera big boost to traditional databases, and because SAP HANA uses disk drives formany non-speed-critical processes (e.g., backup and aged data), they can beused with SAP HANA. Unfortunately, they don't offer much in the way ofimproved speed due to the architecture. Read-only memory is irrelevant."
  },
  {
    "chapter_ID": 1,
    "question_ID": 8,
    "question_text": "True or False: SAP S/4HANA replaces SAP ERP, and development and support are shifted completely to it.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. SAP S/4HANA is the successor to SAP ERP, but it doesn't replace it. Thereis still a huge customer base that relies on SAP ERP, and SAP has promised supportuntil 2027 (at the time of publication) for these customers."
  },
  {
    "chapter_ID": 1,
    "question_ID": 9,
    "question_text": "Which of the following product lines does the SAP S/4HANA system rely on for procurement functions?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "SAP Concur"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "SAP SuccessFactors"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "SAP Ariba"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "SAP C/4HANA"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "SAP Ariba is the product that supports the SAP S/4HANA digital core with procurement.SAP SuccessFactors is for human resources, SAP Concur is for travelmanagement, and SAP C/4HANA supports everything around the customerexperience."
  },
  {
    "chapter_ID": 1,
    "question_ID": 10,
    "question_text": "What technology did SAP leverage to eliminate aggregate tables from the SAP S/4HANA system while maintaining compatibility with many legacy programs?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Columnar store"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Compatibility views"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Data aging"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Indices"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "Compatibility views can be materialized on demand for data in line-item tablesof the SAP HANA database to support programs that read old aggregate andindex tables that are no longer in the system."
  },
  {
    "chapter_ID": 1,
    "question_ID": 11,
    "question_text": "True or False: Choosing between SAP S/4HANA and SAP S/4HANA Cloud, public edition, is also a matter of required scope and Customizing flexibility.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. The product scopes still vary, with SAP S/4HANA being the more com-plete solution currently and offering more customizing possibilities. The SAPS/4HANA Cloud solution is a better fit for new subsidiaries of large enterprisesor companies interested in a lean digital core solution that is always up to datewith the latest features and functions and that can live with the (decreasing)limitations of the public cloud solution. Note that the private edition option isbasically equivalent to the any-premise edition and differs mostly in the licensingmodel (perpetual versus subscription licensing)."
  },
  {
    "chapter_ID": 1,
    "question_ID": 12,
    "question_text": "True or False: The only version of the SAP S/4HANA Cloud, public edition, a customer can be on is the latest released version.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. Quarterly updates in the realm of the multitenant public cloud solutionare always required. A customer can't choose to skip a product release cyclebecause the system is upgraded for all at the same time. All other editions givecustomers more flexibility when selecting their upgrade path and frequency."
  },
  {
    "chapter_ID": 1,
    "question_ID": 13,
    "question_text": "A user wants to add an app to his SAP Fiori homepage but can\u2019t find it in the app finder. What does the admin have to do?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Assign the corresponding SAP Fiori tile group to the user."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Assign the corresponding SAP Fiori tile catalog to the user."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Add the tile to a tile group already assigned to the user."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Add the corresponding tile catalog to the tile group."
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "The SAP Fiori tile catalog defines which tiles a user is authorized to use. If theuser isn't assigned to the right catalog, he won't see the application tile even ifit is part of a group he is also assigned to."
  },
  {
    "chapter_ID": 1,
    "question_ID": 14,
    "question_text": "Where can an SAP Fiori end user change the theme of his SAP Fiori launchpad?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "In the User Menu of the SAP Fiori launchpad"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "In the Tile Catalog area of the SAP Fiori launchpad designer"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "In the Tile Group area of the SAP Fiori launchpad designer"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "In the UI theme designer"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "An end user will only typically have access to the SAP Fiori launchpad; otherSAP Fiori tools are for administrators. The user menu is where users control allchangeable settings for their SAP Fiori launchpads, including theme selection."
  },
  {
    "chapter_ID": 1,
    "question_ID": 15,
    "question_text": "True or False: Anyone with an appropriate user name and password can log on to the SAP Fiori launchpad through a web browser.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. No special software needs to be installed; all modern, up-to-date webbrowsers with HTML5 support will work. In our training system, we recommendusing the Google Chrome browser because it seems currently to offer abetter speed/compatibility mix."
  },
  {
    "chapter_ID": 1,
    "question_ID": 16,
    "question_text": "What kind of customizations can users make to their SAP Fiori launchpads? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Change the name of tile groups."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Create their own tile groups."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Change the names of tiles."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Change the icons on tiles."
      }
    ],
    "answer_correct": [
      "A",
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "Changing the name and look of tiles can only be done in the catalog view of theSAP Fiori launchpad designer. End users can only access the SAP Fiori launchpadwhere they can manage their tile groups."
  },
  {
    "chapter_ID": 1,
    "question_ID": 17,
    "question_text": "Which of the following are SAP Fiori design principles? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Role-based"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Routine"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Rational"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Responsive"
      }
    ],
    "answer_correct": [
      "A",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "SAP Fiori principles mandate that applications should be role-based, catering to specific tasks performed by specific users. SAP Fiori apps should also be responsive,adjusting their interface to work optimally for different devices."
  },
  {
    "chapter_ID": 1,
    "question_ID": 18,
    "question_text": "You use the search in your SAP Fiori launchpad to loop up information for a cost center. What kind of app is used for the information shown in the search results?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Transactional"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Factsheet"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Analytical"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "List"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "The result will be displayed with a factsheet app, which will provide key facts forthe object you searched for. You'll have the option to navigate to a transactionalapp, such as Manage Cost Centers, or an analytical app, such as a cost centerreport. The List answer option isn't an official app type in the SAP Fiori designlanguage."
  },
  {
    "chapter_ID": 2,
    "question_ID": 1,
    "question_text": "Your customer operates 20 company codes in 10 different countries and three continents. What is the minimum number of clients you must configure in the SAP S/4HANA system to depict this scenario?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "3"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "10"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "1"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "20"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "The key word in the question is minimum. You have to think then if there are any restrictions for how many company codes, countries, or even continents. Because there are no restrictions on what kind of organizational units are mapped on the client, the answer is that you can set everything up in a single client."
  },
  {
    "chapter_ID": 2,
    "question_ID": 2,
    "question_text": "What types of objects are maintained separately per client? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Master data"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "ABAP programs"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Organizational units"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Transactional data"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Function modules"
      }
    ],
    "answer_correct": [
      "A",
      "C",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "If you understand the concept of client-dependent and client-independent objects, this is an easy one. You can think of examples of objects for each category to find the correct option: \u2013 Organizational unit: Company code is only available in the client created. \u2013 Master data: General ledger account is only available within the chart of accounts of a specific client. \u2013 Transactional data: General ledger posting is posted to a company code, so it\u2019s only available on the specific client. ABAP code, whether programs or function modules, is always client-independent. If you change a program in a specific client, the change affects all the objects in all clients."
  },
  {
    "chapter_ID": 2,
    "question_ID": 3,
    "question_text": "True or False: You can install as many SAP Best Practices packages as needed in the same client.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. Because each country has at least one SAP Best Practices package, it\u2019s obvious  that there has to be a way to support many packages on the same client. In principle, you can install as many SAP Best Practices packages as desired in a single client."
  },
  {
    "chapter_ID": 2,
    "question_ID": 4,
    "question_text": "What can you manually select to copy when you copy a company code? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Controlling area assignment"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "User profiles and roles"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "General ledger account data"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Company code currency"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Document splitting rules"
      }
    ],
    "answer_correct": [
      "A",
      "C",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "The three correct options are detailed earlier in this chapter (in the \u201cCompany Code\u201d section); however, it isn\u2019t always easy to remember so many details. To answer this, then, you might consider which objects are defined or assigned on the company code level. For example, this would directly eliminate splitting rules because all splitting Customizing is on the client level. Users, roles, and so on aren\u2019t really discussed in the SAP S/4HANA financial accounting SAP Training and Adoption courses nor in this book. Remember, all referenced directly in the financial accounting training materials. The closest we come to discussing users, roles, and authorizations are the tolerance profiles, and even those work across company codes."
  },
  {
    "chapter_ID": 2,
    "question_ID": 5,
    "question_text": "What are the three basic steps required to define a new company code? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Adjust global parameters"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Copy existing company code"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Copy existing controlling area"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Define new segments"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Adjust basic and address data"
      }
    ],
    "answer_correct": [
      "A",
      "B",
      "E"
    ],
    "score":0,
    "responded":false,
    "explanation": "Following are the three steps in the correct order: \u2013 Copy an existing company code, specifically the company code that looks most like the want you want to create. \u2013 Adjust basic and address data by changing the name and address details of the new company code. \u2013 Adjust global parameters. All the core settings for the company code need to be checked, such as the chart of accounts, fiscal year, and so on."
  },
  {
    "chapter_ID": 2,
    "question_ID": 6,
    "question_text": "Which of the following assignments can be adjusted for the company code? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Company"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Fiscal year variant"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Segment"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Chart of accounts"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Leading ledger"
      }
    ],
    "answer_correct": [
      "A",
      "B",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "You need to be familiar with the core objects and their assignments. The leading ledger is assigned to all company codes because there is only a single leading ledger per client. The segments are defined on the client level and assigned to profit centers. Yes, profit centers are assigned to company codes, so you could argue about this (this is an example of an answer that would probably be rejected in a certification exam)."
  },
  {
    "chapter_ID": 2,
    "question_ID": 7,
    "question_text": "True or False: You need to assign the workflow variant to a company code to be able to perform standard workflow scenarios such as parked document approvals.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. This is basically the definition of the workflow variant. The variant itself is where rules are defined. In the certification, when an answer to a question seems obvious, it usually is!"
  },
  {
    "chapter_ID": 2,
    "question_ID": 8,
    "question_text": "Which of the following organizational units are part of the enterprise structure of financial accounting? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Functional area"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Operating concern"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Business area"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Plant"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Segment"
      }
    ],
    "answer_correct": [
      "A",
      "C",
      "E"
    ],
    "score":0,
    "responded":false,
    "explanation": "The functional area is used for cost of sales accounting in financial accounting, the business area depicts a separate business division, and the segment is used for segment reporting. The operating concern is a management accounting (profitability analysis) organizational unit, and the plant is the basic logistics organizational unit."
  },
  {
    "chapter_ID": 2,
    "question_ID": 9,
    "question_text": "For which of the following account assignments can you configure the system to create complete balance sheet reports? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Cost center"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Profit center"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Segment"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Company code"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Profitability segment"
      }
    ],
    "answer_correct": [
      "B",
      "C",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "The company code is the default level for balance sheet reports in financial accounting. The profit center and segment are both definable in document splitting as splitting characteristics, and thus the system also can be configured to produce balance sheets at their level. The cost center can only be posted for P&L accounts that are cost elements, and the same goes for the profitability segment."
  },
  {
    "chapter_ID": 2,
    "question_ID": 10,
    "question_text": "True or False: You assign the operating concern used for profitability analysis directly to the company code.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. The operating concern is assigned one to many to the controlling area. The company code is assigned to the operating concern indirectly, through the assignment of the company code to the controlling area."
  },
  {
    "chapter_ID": 2,
    "question_ID": 11,
    "question_text": "True or False: To integrate financial accounting with purchasing, you must assign the purchasing organization to the company code.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. When you don\u2019t assign the company code to the purchasing organization, the system uses the plant to derive the correct one."
  },
  {
    "chapter_ID": 2,
    "question_ID": 12,
    "question_text": "What types of chart of accounts are there? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Organizational chart of accounts"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Country chart of accounts"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Functional chart of accounts"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Operational chart of accounts"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Group chart of accounts"
      }
    ],
    "answer_correct": [
      "B",
      "D",
      "E"
    ],
    "score":0,
    "responded":false,
    "explanation": "The operational chart of accounts and the country/local chart of accounts are assigned to the company code. The group chart of accounts is assigned to the operational chart of accounts. The other two types don\u2019t exist. In the certification exam, the exam writers can\u2019t make up objects that aren\u2019t actually in the system (however, they can use objects that exist but aren\u2019t taught in the courses as wrong selections). So, this would be another rejected question that wouldn\u2019t be on a certification exam."
  },
  {
    "chapter_ID": 2,
    "question_ID": 13,
    "question_text": "Which objects can define the maximum length of a general ledger account? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Chart of accounts"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Field status group"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Account type"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Account group"
      }
    ],
    "answer_correct": [
      "A",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "In the definition of the chart of accounts, you define the maximum length of the accounts. In the account group, you define the number range; the number range by default also controls the length of the field. Whichever is shortest takes priority for the account length definition."
  },
  {
    "chapter_ID": 2,
    "question_ID": 14,
    "question_text": "True or False: With the block indicator set on the chart of accounts, you can no longer perform postings to the company codes assigned.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. The indicator blocks the accounts in the chart of accounts from being assigned to a company code. This won\u2019t affect accounts already assigned before this indicator was set and won\u2019t stop you from posting to the accounts of the chart of accounts."
  },
  {
    "chapter_ID": 2,
    "question_ID": 15,
    "question_text": "Which fields in the company code definition of a general ledger account are always required? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Sort Key"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Account Currency"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Field Status Group"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Group Account"
      }
    ],
    "answer_correct": [
      "B",
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "The account currency and the field status group always need to be defined to create an account. These can\u2019t be affected by the field status definition of the account group. The group account is defined on the chart of accounts level, and it\u2019s indeed a required field when a group chart of accounts is assigned to the operational chart of accounts. The sort key isn\u2019t a required field, but it\u2019s very useful, and filling it in is very much recommended to help with reporting on line items."
  },
  {
    "chapter_ID": 2,
    "question_ID": 16,
    "question_text": "When creating a general ledger account and there are conflicting field statuses, which field status has the highest priority?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Display"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Optional"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Suppress"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Required"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "The Suppress field status has highest priority in this case. The priority in detail is Suppress, Display, Required, Optional. This is the most common status prioritization in the SAP S/4HANA system."
  },
  {
    "chapter_ID": 2,
    "question_ID": 17,
    "question_text": "What is the secondary cost general ledger account type used for?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "To post to two cost controlling account assignments at the same time"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "To post activities that aren\u2019t from the main businesses of the company"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "To post internal cost allocations in management accounting"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "To post cost controlling account assignments statistically for fixed asset and material accounts"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "You use secondary cost accounts to post transfer postings in controlling. Among all secondary cost accounts, the balance is always zero for a company code because you can only debit a secondary account by crediting a secondary general ledger account. Secondary cost general ledger accounts are new with SAP S/4HANA. In the traditional SAP ERP system, you defined secondary cost elements (the SAP S/4HANA system does this as well via tables in the background)."
  },
  {
    "chapter_ID": 2,
    "question_ID": 18,
    "question_text": "True or False: If you maintain a single value for the retained earnings account, then for any P&L general ledger account you create, the P&L statement account type is assigned automatically.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. You only need to define the retained earnings account manually if you\u2019ve defined more than one retained earnings account. In this case, however, automatic assignment is no longer possible, and you always have to input the correct type manually."
  },
  {
    "chapter_ID": 2,
    "question_ID": 19,
    "question_text": "What is the relationship between accounts of the operating chart of accounts and the local chart of accounts?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "One operating account to many local accounts"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Many operating accounts to one local account"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Many operating accounts to many local accounts"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "One operating account to one local account"
      }
    ],
    "answer_correct": [
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "In this case, the relationship is one to one. When you assign a local chart of accounts in the company code, you can only assign a local account to a single account of the operational chart of accounts."
  },
  {
    "chapter_ID": 2,
    "question_ID": 20,
    "question_text": "You have to map two accounting principles for accounting. Using the accounts approach to parallel valuation in SAP S/4HANA, which ledger must you use?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "No ledger"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Leading ledger"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Extension ledger"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Special purpose ledger"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "Note the \u201cmust\u201d in the question. As was the case from the new general ledger already, all company codes are, at a minimum, assigned to the leading ledger."
  },
  {
    "chapter_ID": 2,
    "question_ID": 21,
    "question_text": "True or False: When you create a new standard ledger from scratch, it\u2019s assigned to all company codes by default.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. A new standard ledger won\u2019t be assigned to any company codes until you manually make the assignment and configure the ledger settings. If you create the ledger via copying, you can choose to copy the assignments and settings from the original to the new one."
  },
  {
    "chapter_ID": 2,
    "question_ID": 22,
    "question_text": "You indicate that you\u2019re posting to the ledger group of an extension ledger. How many ledgers are updated?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Only the extension ledger"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "The extension ledger and the underlying ledger"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "The leading ledger and the extension ledger"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "It depends on the ledgers grouped in the ledger group"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "The ledger group that contains the extension ledger is generated automatically when you create the ledger group, and there is no way to assign an extension ledger to any other ledger group. Thus, when posting to the ledger group of the extension ledger, you can only update the extension ledger."
  },
  {
    "chapter_ID": 2,
    "question_ID": 23,
    "question_text": "True or False: When using the accounts approach to parallel valuation for mapping to accounting principles, you\u2019ll essentially double the general ledger accounts in the chart of accounts.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. You only need to create two sets of accounts for those accounts for which there can be differences in valuation. This doesn\u2019t mean all accounts need to be duplicated. In most cases, independent of the valuation approach, the accounting entries can be valuated only in a single way for all accounting principles. For these, you can have common accounts."
  },
  {
    "chapter_ID": 2,
    "question_ID": 24,
    "question_text": "True or False: You can use an extension ledger to depict a separate accounting principle.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. Although you have limitations because you can only perform manual postings, you can assign an extension ledger group to an accounting principle and thus have a complete, separate valuation."
  },
  {
    "chapter_ID": 2,
    "question_ID": 25,
    "question_text": "True or False: SAP S/4HANA comes preconfigured with common accounting principles and corresponding accounting rules and checks assigned.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. Although an SAP Best Practices package or client 000 might indeed have an accounting principle predefined, the accounting principle object carries no valuation rules or checks in and of itself."
  },
  {
    "chapter_ID": 2,
    "question_ID": 26,
    "question_text": "What is defined with the fiscal year variant? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Open and closed fiscal periods"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Start and end dates of the calendar year"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Start and end dates of the fiscal year"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Number of fiscal periods in a year"
      }
    ],
    "answer_correct": [
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "The open and closed periods are defined per period variant, and the start and end dates of the calendar year are universally accepted and not defined in the SAP system."
  },
  {
    "chapter_ID": 2,
    "question_ID": 27,
    "question_text": "How many custom currency types can you assign to a company code/ledger combination?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "6"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "8"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "10"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "12"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "An SAP S/4HANA system supports 10 currency types in parallel per company code/ledger combination, but 2 of these are always reserved. The company code currency and the global currency (controlling area currency) aren\u2019t changeable. Thus, you can assign up to 8 custom currency types to fill the maximum 10."
  },
  {
    "chapter_ID": 2,
    "question_ID": 28,
    "question_text": "What does the posting key define? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Account type"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Document type"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Tax code"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Debit or credit line"
      }
    ],
    "answer_correct": [
      "A",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "This one is straightforward; the posting key tells the system two things: \u2013 What kind of account you\u2019re posting to (general ledger, asset accounting, accounts payable, accounts receivable) \u2013 If the line is a debit or credit entry (for general ledger postings, 40 means debit and 50 means credit)"
  },
  {
    "chapter_ID": 2,
    "question_ID": 29,
    "question_text": "What can you control with the tolerance group? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Maximum amount per open item"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Maximum amount per document"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Maximum permitted payment differences"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Maximum tax amount"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Maximum exchange rate deviation"
      }
    ],
    "answer_correct": [
      "A",
      "B",
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "The maximum exchange rate deviation is defined in the company code global settings. The maximum tax amount isn\u2019t a very plausible option here."
  },
  {
    "chapter_ID": 2,
    "question_ID": 30,
    "question_text": "What do you define for a tax code? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Tax percentage rates"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Fixed tax amount"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Tax-relevant accounts"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Valid tax types"
      }
    ],
    "answer_correct": [
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "In Transaction FTXP, in which you define the tax codes, you define the valid tax codes per country. Within the tax code definition, you assign the relevant tax percentage rate to the tax types that are relevant. You define whether an account is relevant for tax in the general ledger account maintenance on the company code level."
  },
  {
    "chapter_ID": 2,
    "question_ID": 31,
    "question_text": "Which document splitting setting for the segment characteristic will ensure that no document line will be posted without a segment?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Inheritance"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Zero balance"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Mandatory field"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Passive split"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "The only selection that will absolutely guarantee that no document can ever be posted without the segment is to set the segment as required. The system will always raise an error and not post the document if a line is missing the segment assignment."
  },
  {
    "chapter_ID": 2,
    "question_ID": 32,
    "question_text": "Your company code is assigned to two standard ledgers and one extension ledger. You post an invoice with one expense line item with a 10% VAT (sales) tax. How many line items are posted in the document entry view (table BSEG)?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "2"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "3"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "4"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "6"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "The entry view is the document posted before taking into consideration document splitting and ledger-specific line items. So, it\u2019s a pretty straightforward calculation: \u2013 One line for the supplier \u2013 One line for the expense \u2013 One line for the tax Three lines total are posted in the entry view (updated in line-item table BSEG)."
  },
  {
    "chapter_ID": 2,
    "question_ID": 33,
    "question_text": "Your company code is assigned to two standard ledgers and one extension ledger. You post an invoice with one expense line item with a 10% VAT (sales) tax. How many line items are updated in the Universal Journal (table ACDOCA)?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "2"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "3"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "4"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "6"
      }
    ],
    "answer_correct": [
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "The Universal Journal line items (updated in table ACDOCA) depict the document after taking into consideration document splitting and ledger-specific line items. Document splitting with one expense line won\u2019t create new line items; inheritance would add the relevant account assignments (typically profit center and segment) to the tax and supplier line items. The standard ledgers are both updated with this document posting. The extension ledger values are updated: not by posting lines specific to the extension ledger, but through the underlying ledger the extension ledger is assigned to. So, analytically, you\u2019ll have the following: \u2013 One line for the supplier for each standard ledger \u2013 One line for the expense for each standard ledger \u2013 One line for the tax for each standard ledger Six lines total are posted in the Universal Journal."
  },
  {
    "chapter_ID": 2,
    "question_ID": 34,
    "question_text": "Which date defines the period a document can be posted to?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Document date"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Valuation date"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Posting date"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Entry date"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "The posting date is the only date that defines the period in which the system will post the document. If a period is already closed, you need to select a posting date in an open period. An exception here is defined for the special posting periods: in case of posting to a special period (period 13, 14, 15, or 16 in a 12-period fiscal year), you need to choose a posting date in the last period of the fiscal year."
  },
  {
    "chapter_ID": 2,
    "question_ID": 35,
    "question_text": "Which of the following fields can be configured as changeable in a document header?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Document Type"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Posting Date"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Document Date"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Document Reference"
      }
    ],
    "answer_correct": [
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "Only two fields can be defined as changeable in the document header segment of a financial accounting document: the document reference and the document header text. The only way to correct any other wrong fields in the header is to reverse and repost the document."
  },
  {
    "chapter_ID": 2,
    "question_ID": 36,
    "question_text": "What fields do you have to enter to make a document reversal? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Document Type"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Document Date"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Posting Date"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Reversal Reason"
      }
    ],
    "answer_correct": [
      "C",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "When you select a single document or multiple documents to reverse, you must define the posting date for the reversal document and the reversal reason. The reversal document type depends on the original document\u2019s document type. The document date isn\u2019t entered manually and is the same as the document date of the original document."
  },
  {
    "chapter_ID": 2,
    "question_ID": 37,
    "question_text": "True or False: A negative posting reversal debits the credit line items and debits the credit line items of the original document.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. This statement describes a normal reversal. With a negative reversal, the system posts a negative amount on the same debit/credit side as the original document line items. This ensures that the total debit/credit for the account isn\u2019t affected by the wrong posting."
  },
  {
    "chapter_ID": 2,
    "question_ID": 38,
    "question_text": "True or False: A customer account can be defined as the clearing account for cross-company-code postings.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. There is no requirement that the clearing account be a general ledger account only. You can define a customer or a vendor account to be posted to for the clearing line items that balance intercompany postings for each company code. You must define an appropriate posting key as well."
  },
  {
    "chapter_ID": 2,
    "question_ID": 39,
    "question_text": "True or False: Using cost of sales accounting can lead to a different result (profit/loss amount) at the end of a period compared to period accounting.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False.Although the grouping of the amounts is different, the financial result is always the same with either the cost of sales or period accounting methods. The difference between the two is in the breakdown detail of the costs and revenues."
  },
  {
    "chapter_ID": 3,
    "question_ID": 1,
    "question_text": "Which business partner categories does the system support? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Group"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Agency"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Person"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Organization"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Public sector"
      }
    ],
    "answer_correct": [
      "A",
      "C",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "The partner categories are person, organization, and group. These are fixed categories; you can\u2019t change or add any. Agency and public sector fall into the organization category."
  },
  {
    "chapter_ID": 3,
    "question_ID": 2,
    "question_text": "You\u2019ve mistakenly created your business partner with the wrong business partner category. You\u2019ve saved the data but haven\u2019t made any postings. What can you do to correct this?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Change the setting directly in the business partner master record."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Contact the system administrator to change the table entry in the backend."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Create a new business partner using the correct category."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Delete the company code assignment for the business partner, correct the category, and reassign the company code."
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "The only way to correct this is to create a new business partner and mark the erroneous one for deletion. Changing this isn\u2019t a matter of authorization, nor does it make any difference if you\u2019ve posted on the business partner or assigned it to a company code. However, if you\u2019ve posted, the best option is to reverse the documents and repost on the correct business partner."
  },
  {
    "chapter_ID": 3,
    "question_ID": 3,
    "question_text": "True or False: After assigning a role to the business partner and saving your data, you\u2019re no longer able to remove the role assigned.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. You can change the role assignment, and some roles are even designed to be temporary roles."
  },
  {
    "chapter_ID": 3,
    "question_ID": 4,
    "question_text": "True or False: The business partner role grouping is where you assign the business partner number range.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. This is incorrect. You assign the business partner number range in the business partner grouping. The role grouping is only useful for performing common maintenance of more than one business partner role and has no part in the number range definition."
  },
  {
    "chapter_ID": 3,
    "question_ID": 5,
    "question_text": "You\u2019re configuring a new SAP S/4HANA system. What are your options for defining the number range assignments for business partners?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "The supplier drives the number range used."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "The customer drives the number range used."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "The business partner drives the number range used."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Whichever account is created first drives the number range used."
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "In a new SAP S/4HANA system, the business partner is defined first and is assigned a number. The customer and supplier created for the business partner inherit the business partner account number."
  },
  {
    "chapter_ID": 3,
    "question_ID": 6,
    "question_text": "Which business partner objects can you define field statuses for? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Account group"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Client"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Role"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Type"
      }
    ],
    "answer_correct": [
      "C",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "This is a bit of a trick question. In the certification, you won\u2019t see trick questions, so kudos if you got this one right! All the objects displayed can have a separate field status definition, but only two of them can be considered business partner objects: business partner role and business partner type."
  },
  {
    "chapter_ID": 3,
    "question_ID": 7,
    "question_text": "Which field cannot be changed during document posting for the one-time customer?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Customer Number"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Customer Name"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Customer Industry"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Customer Country"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "The customer number can\u2019t be changed. The customer number isn\u2019t an identifying characteristic for one-time customers because more than one will share it! You can enter the customer name, industry, country, and other address and bank details during the posting transaction in the special popup window."
  },
  {
    "chapter_ID": 3,
    "question_ID": 8,
    "question_text": "True or False: Bank details for a business partner are part of the general business partner role.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. Bank details, meaning the business partner\u2019s bank accounts for where they receive and from where they send their payments, are assigned in the General Data section and are thus common and usable independent of the assigned business partner roles. The bank details you might enter in the customer/supplier payment section (House Bank, Account ID) are per company code and define from where the company code should pay or receive payments."
  },
  {
    "chapter_ID": 3,
    "question_ID": 9,
    "question_text": "Which field in the business partner master can you configure so that the document reference is transferred to the line item?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Assignment"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Reference"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Line Item Text"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Sort Key"
      }
    ],
    "answer_correct": [
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "The sort key defines what is transferred to the assignment field on the line item. Here, you shouldn\u2019t be confused by the field names and remember where everything belongs. The assignment is the actual field where such information is transferred; the reference is a document header field. The line-item text can carry the information (not automatically, except maybe with a substitution), but, more importantly, it\u2019s a document-level field and not maintained in the business partner. Remember to read exactly what the question is asking for. Don\u2019t get distracted by answers that seem to fit but, in the end, have nothing to do with the question."
  },
  {
    "chapter_ID": 3,
    "question_ID": 10,
    "question_text": "A business partner is both a customer and a supplier. The customer has a sizable open amount unpaid. You want to make sure that the payment program posts no outgoing payments to the business partner until he has paid part of the outstanding amount. How can you do this?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Assign an overall payment block in the general business partner role details."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Assign a central purchasing block in the general business partner role details."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Assign a payment block in the customer business partner role details."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Assign a payment block in the supplier business partner role details."
      }
    ],
    "answer_correct": [
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "Here, D is the obvious choice. An overall payment block would block incoming payments as well as outgoing, which is the same as assigning the block on the customer. A purchasing block would mean you wouldn\u2019t be able to use the supplier to order goods, which isn\u2019t mentioned as a desired outcome in the scenario. Blocking the supplier for payment ensures that you won\u2019t pay for any open items, at least not without a manual supervision of the payment. (Don\u2019t let questions like this one with a lot of text cause you to panic during the examination for fear of running out of time. The writing guidelines for questions generally maintain that the questions should be concise and carry mostly only information absolutely required to answer the question. This means, in most cases, it\u2019s a good idea to read every detail of the question. It also means that most questions aren\u2019t very long, so you can afford to spend a little more time on the few longer ones without worrying about running out of time.)"
  },
  {
    "chapter_ID": 3,
    "question_ID": 11,
    "question_text": "What can you achieve by using the head office/branch scenario?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Settings changed for the head office master record will be transferred to the branch account."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Postings made to the head office also can be paid to the branch account."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Invoices posted to the branch become open items for the head office."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Correspondence and dunning letters are printed and sent to both the branch and the head office."
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "The definition of the head office/branch scenario is to have centralized open item and payment management. Thus, C is the only answer that fits the bill. There is no kind of automatic master data synchronization with a head office. You pay the head office because that is where the open item is, and correspondence can be sent either to the head office or to the branch depending on the setting in the business partner master data."
  },
  {
    "chapter_ID": 3,
    "question_ID": 12,
    "question_text": "Which configuration can you use to define separate payment terms for the same terms of payment key?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Day limit"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Company code"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Country"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Installment payment"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "The day limit key allows you to do this. The day limit together with the terms of payment are the key fields in the relevant definition, which means you can differentiate either field to have an additional payment term. You can have multiple term of payment definitions differentiated by the day limit. The level you would need to change to create terms of payments with the same key otherwise would be the client because the terms of payment are client-dependent."
  },
  {
    "chapter_ID": 3,
    "question_ID": 13,
    "question_text": "You\u2019ve agreed to terms of payment with a business partner that is a customer and a supplier. The same terms are to be used both ways (incoming and outgoing). You\u2019ve defined a new terms of payment key, but when trying to update the supplier business partner role, you don\u2019t find the payment term available to select. What must you do?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Create a new payment term for the supplier business partner role."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Enter the payment term manually during invoicing."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Maintain the account type in the terms of payment."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Change the field status of the business partner role to make the payment terms an optional field."
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "This is a troubleshooting question, which tests understanding and knowledge of the functionality of the system. However, these questions aren\u2019t often used in the exams because it\u2019s hard to make them unambiguous. In this example, the terms of payment (answer C) must have been configured without the supplier account type. However, one could argue that A would work as well, and, yes, it would. B also kind of works, but it\u2019s no real solution. D is clearly wrong; the specific payment term was missing per the scenario in the question, and D would indicate the field itself was missing, which isn\u2019t implied anywhere. So why is C the correct choice? It\u2019s by far the most proper way to handle the situation. Creating additional records when you could use what is there just adds noise to an already complex system. You want to always try and simplify for the end users."
  },
  {
    "chapter_ID": 3,
    "question_ID": 14,
    "question_text": "You\u2019ve negotiated payment terms that include three installment payments with your customer. How many terms of payment must you define?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "1"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "2"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "3"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "4"
      }
    ],
    "answer_correct": [
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "To configure installments, you need a base payment term and then an additional payment term per number of installments. In Customizing, you assign each installment payment term to the base payment term."
  },
  {
    "chapter_ID": 3,
    "question_ID": 15,
    "question_text": "What does assigning a screen variant during document entry do?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "It defaults values for fields in the document line item."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "It changes the fields that are ready for entry in the document header."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "It changes the fields that are ready for entry in the line items."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "It defaults values for fields in the document header."
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "The screen variant is an entry that simplifies the line-item section of the screen by displaying only those items that are required to be entered or at least a much smaller selection of account assignments than what is on the standard line items. The screen variant has no effect on the header section, and it has no predefined values attached to it."
  },
  {
    "chapter_ID": 3,
    "question_ID": 16,
    "question_text": "When setting up the system, you want to maintain default values for users of a company code. Which application do you use?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "User Maintenance"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Maintain User Profile"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Default Values (SAP Fiori)"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "As a consultant, you would use the User Maintenance applications (Transaction SU01 or, better yet, Transaction SU10). With these transactions, you can change users other than your own. Both the user profile and the default values in the SAP Fiori launchpad are limited to defaulting values only for the logged-on user."
  },
  {
    "chapter_ID": 3,
    "question_ID": 17,
    "question_text": "Your customer doesn\u2019t want to implement profitability analysis, not even account-based, because the company doesn\u2019t understand its value. Which argument would you use to persuade the customer?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Profit and loss statements can be analyzed by sales-specific characteristics."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Balance sheets can be analyzed by sales-specific characteristics."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Sales orders can be forecasted as liquidity items in cash flow reports."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Down payment integration can be performed with sales and distribution."
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "This question is borderline as far as whether it can be answered with the content in this book. Although we try to be consistent with the \u201ceverything can be found in the materials\u201d rule, there are some questions for which it helps (but isn\u2019t strictly required) to have some \u201coutside\u201d experience to answer. In this case, you know that many fields coming from sales and distribution are transferred to the Universal Journal as values of CO-PA characteristics. Anything that finds itself as information on the line item in the Universal Journal can be analyzed in reports, including the P&L statement, so A is the distractors here are wrong: CO-PA is updated for P&L accounts, so no balance sheet entries (usually); liquidity forecasting has nothing to do with CO-PA; and the same is true for payment and down payment processing. SAP recommends that account-based CO-PA be activated for all SAP S/4HANA systems by default, even if a customer won\u2019t initially make use of it."
  },
  {
    "chapter_ID": 3,
    "question_ID": 18,
    "question_text": "What can you enter in the header section of the Post Outgoing Payments app? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Value date"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Bank fees"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Discount amount"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Customer"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Payment terms"
      }
    ],
    "answer_correct": [
      "A",
      "B",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "This is a straightforward question for which it helps to be able to bring the entry screen to your mind. If that doesn\u2019t work, you can think of eliminating distractors. The Discount Amount is a line-item setting because it might be different for the items you clear. The same is true for the Payment Terms field (which would be weird to change during the payment entry)."
  },
  {
    "chapter_ID": 3,
    "question_ID": 19,
    "question_text": "You receive a payment for an invoice that is smaller than the owed amount. How can you manage payment differences in the Post Incoming Payments app? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Partial payment"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Residual item"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Change due date"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Reject payment"
      }
    ],
    "answer_correct": [
      "A",
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "The standard way of handling payments that are smaller than the assigned open items is to create either a partial payment or a residual item. If neither works, you handle the differences manually (post to general ledger or post on account). There is no functionality to reject a payment. You could return it on the bank level, but generally you would post the payment and then talk to the customer to understand the amount discrepancy. Changing the due date is irrelevant to how or if you post the payment."
  },
  {
    "chapter_ID": 3,
    "question_ID": 20,
    "question_text": "When a payment in foreign currency clears the amount of the invoice exactly in the foreign currency but in the local currency is more than the invoiced amount, what does the system do?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Post to profit from exchange rate differences."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Leave the amount as an open item to be reconciled with open amounts in the future."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Return the difference back to the customer."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Adjust the local currency amount to clear the invoice exactly."
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "This is a straightforward question with a simple answer: differences from currency exchange are posted automatically to the accounts you\u2019ve specified in Customizing for profits or losses from exchange rate differences."
  },
  {
    "chapter_ID": 3,
    "question_ID": 21,
    "question_text": "What is the correct order of steps when configuring the payment programs?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "All company codes, paying company codes, payment methods in country, payment methods in company code, bank determination"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "All company codes, payment methods in country, paying company codes, payment methods in company code, bank determination"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "All company codes, bank determination, paying company codes, payment methods in country, payment methods in company code"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Paying company codes, payment methods in company code, all company codes, payment methods in country, bank determination"
      }
    ],
    "answer_correct": [
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "The correct configuration order is: \u2013 All company codes, where you assign paying company codes to the ones that don\u2019t perform payments. \u2013 Paying company codes, where you configure the company codes that do perform payments. \u2013 Payment methods per country, where you define usable payment methods for different countries. \u2013 Payment methods per company code, were you setup the ones valid for each company code, out of the ones available for the country. \u2013 Define the bank determination for the automated payments."
  },
  {
    "chapter_ID": 3,
    "question_ID": 22,
    "question_text": "In the payment method settings for the company code, what options do you have for payment optimization? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Optimization by due date"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Optimization by discount"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Optimization by bank group"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Optimization by postal code"
      }
    ],
    "answer_correct": [
      "C",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "This is a very specific question that concerns settings on a Customizing screen. There you can select to optimize per bank group, meaning the system will give priority to paying from bank accounts that are in the same bank group as the business partners. You can also optimize by postal code, meaning the preferred bank will be the closest (according to postal codes) to the business partner."
  },
  {
    "chapter_ID": 3,
    "question_ID": 23,
    "question_text": "What can you change for items that are in the exceptions list of the payment proposal? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Payment block"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Payment term"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Payment method"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Bank selection"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Reason code"
      }
    ],
    "answer_correct": [
      "A",
      "C",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "You can remove the blocking reason (if it\u2019s allowed by the definitions in Customizing), you can enter or change the payment method, and you can enter or change the selected house bank account. In addition, although not one of the answer options, you could also alter the discount amount (per the tolerances allowed)."
  },
  {
    "chapter_ID": 3,
    "question_ID": 24,
    "question_text": "On what level do you assign dunning areas?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Client"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Company code"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Functional area"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Segment"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "Dunning areas are used to subdivide company codes into areas of responsibility for dunning, so the answer is the company code where you assign these. Dunning areas might well reflect either segments or functional areas, but there is no system connection or assignments between these."
  },
  {
    "chapter_ID": 3,
    "question_ID": 25,
    "question_text": "When assigning dunning charges to a dunning procedure, what options do you have? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Assign a fixed amount without assigning a dunning charge percentage."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Assign a fixed amount and a percentage. The system posts the larger amount."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Assign a fixed amount and a percentage. The system posts the smaller of the two amounts."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Assign a dunning charge percentage without assigning a fixed amount."
      }
    ],
    "answer_correct": [
      "A",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "You can assign either an absolute value for the dunning charge or a percentage (which is calculated off the total amount of the overdue items). You can\u2019t select both. You enter the charges per level and per outstanding amount, so that is where you can differentiate the charges assigned."
  },
  {
    "chapter_ID": 3,
    "question_ID": 26,
    "question_text": "True or False: You can assign correspondence types directly to a payment difference reason code.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. In the definition of the payment difference reason codes, you can indeed assign a specific correspondence type to generate correspondence for the difference."
  },
  {
    "chapter_ID": 3,
    "question_ID": 27,
    "question_text": "True or False: You can change all delivered special general ledger indicators and assign your own to business processes.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. This wasn\u2019t discussed specifically in the chapter, but it\u2019s good to know. There are certain processes that are tied to the specific special general ledger accounts being configured in a specific way. For example, the down payment integration scenario uses F for down payment requests and A for down payments. These shouldn\u2019t be changed."
  },
  {
    "chapter_ID": 3,
    "question_ID": 28,
    "question_text": "At which point is the down payment request created in the integrated down payment process with purchasing?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Purchase requisition"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Purchase order"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Goods receipt"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Invoice verification"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "You enter that a down payment is expected in the purchase order, and this generates the down payment request in financial accounting."
  },
  {
    "chapter_ID": 3,
    "question_ID": 29,
    "question_text": "True or False: The special general ledger indicator is the only way to post to a customer or supplier using a different reconciliation account.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. As mentioned, you can also configure the reconciliation account field to be changeable/selectable during document posting. This eliminates the need for a special general ledger transaction in certain cases."
  },
  {
    "chapter_ID": 3,
    "question_ID": 30,
    "question_text": "Which KPIs can you find on the accounts payable overview page? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Day sales outstanding"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Cash discount utilization"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Aging analysis"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Collection effectiveness index"
      }
    ],
    "answer_correct": [
      "B",
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "The the ones that pertain to payables functions instead of receivables. You want to track cash discounts to make sure you\u2019re taking advantage of all discounts offered, so that is one. The other , the aging analysis; if the answer was specifically payables aging, it would be a dead giveaway. There might be some instances like this in the exam as well, in which answer options are made a little more \u201cgeneral\u201d without making them wrong or unfair. The two other options are receivables KPIs, so you can be sure they are wrong."
  },
  {
    "chapter_ID": 4,
    "question_ID": 1,
    "question_text": "Which system components are directly integrated with asset accounting? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Bank account management"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Investment management"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Purchasing"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Plant maintenance"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Quality management"
      }
    ],
    "answer_correct": [
      "B",
      "C",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "Investment management is tightly integrated, especially with AUCs. Purchasing is integrated with asset accounting already from the purchase requisition phase, and the asset is a required field on the purchase order for asset acquisition. Plant maintenance integrates through the equipment master record connection to the fixed asset master. Bank account management isn\u2019t directly integrated with fixed assets, and the same is true with quality management, which integrates with materials management."
  },
  {
    "chapter_ID": 4,
    "question_ID": 2,
    "question_text": "What is true for assigning charts of depreciation to company codes?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "All company codes of a single country must be assigned to the same chart of depreciation."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Each company code can be assigned to a different chart of depreciation."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "You assign company codes only to charts of depreciation delivered by SAP."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "A company with company codes in multiple countries can use a single chart of depreciation for all."
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "There is no limitation on the number of charts of depreciation created for a country. You assign a chart of depreciation to each company code, and again, there is no rule to assign the same one to company codes belonging to the same country. You can use a standard chart of depreciation as delivered by SAP, but if you want to make modifications, it\u2019s advised that you copy it and then modify the new chart. Asset accounting has very different accounting rules in different countries, so you wouldn\u2019t use the same chart of depreciation across country borders. Even if things look the same to begin with, laws and regulations change all the time, and you never know when a country will impose a rule incompatible with other countries."
  },
  {
    "chapter_ID": 4,
    "question_ID": 3,
    "question_text": "True or False: A single depreciation area can post to multiple ledgers.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. Although true, this is a kind of tricky question. You assign an accounting principle to the depreciation area. The accounting principle is assigned to a ledger group. The ledger group can have multiple ledgers assigned, so many ledgers can be updated by a depreciation area."
  },
  {
    "chapter_ID": 4,
    "question_ID": 4,
    "question_text": "What is true for real depreciation areas?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "They are set to always post to the general ledger."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Each can be assigned multiple currency types."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "You can post transactions to them independently."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "They can have values calculated from combining other depreciation area values."
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "In asset accounting transactions that aren\u2019t integrated with accounts payable/ accounts receivable, you can choose to post them to specific depreciation areas. In addition, if a depreciation area isn\u2019t valid for an individual asset, you can deactivate it. The asset values then won\u2019t be updated for the deactivated area even for external transactions (an expense or loss account is debited to balance the technical clearing account in such cases). A real depreciation area doesn\u2019t have to post to the general ledger; each area is assigned a specific currency type. Calculating values from other areas is the exact reason for having derived (not real) depreciation areas."
  },
  {
    "chapter_ID": 4,
    "question_ID": 5,
    "question_text": "Two assets (belonging to the same company code) post depreciation to different general ledger accounts. What does this mean for the assets? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "The assets belong to different asset classes."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "The assets are assigned to separate account determinations."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "The assets post acquisition and production costs (APC) values to separate accounts."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "The assets are assigned to separate cost centers."
      }
    ],
    "answer_correct": [
      "A",
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "The definite obvious answer here is that the assets have different account determinations. This also means the assets belong to different asset classes because an asset class is assigned to a single account determination. Although unlikely, the APC account could be the same between separate account determinations. The cost center has no bearing on the selected accounts."
  },
  {
    "chapter_ID": 4,
    "question_ID": 6,
    "question_text": "True or False: Two assets in the same asset class, belonging to different company codes but with the same chart of depreciation, can post to different accounts.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. Here, as with the certification questions, you need to read the question through and find the essence of the information given. There is almost no chance that some piece of information is given to distract. Here, the key is that the company codes can have different charts of accounts assigned. Because the accounts in the account determination are defined per chart of accounts, the two assets could post to separate accounts."
  },
  {
    "chapter_ID": 4,
    "question_ID": 7,
    "question_text": "How can you default a value for the cost center in the entire asset class for buildings?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "By using asset subnumbers"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "By changing the assigned screen layout"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "By changing the assigned tab layout"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "By entering it in default account assignments for the general ledger account"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "In the screen layout, you can define that a field can be set on the asset class level. You can do the same for the subnumbers, but another main asset in the asset class wouldn\u2019t be limited. The tab layout only affects the positioning of the tabs. The cost center assigned to the asset can\u2019t be affected by a rule in the default account assignments."
  },
  {
    "chapter_ID": 4,
    "question_ID": 8,
    "question_text": "Which of the options are types of user fields? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Group asset"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Serial number"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Evaluation group"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Asset super number"
      }
    ],
    "answer_correct": [
      "C",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "This is a straightforward knowledge question. You usually answer these by a combination of knowing part of the right answer and dismissing some of the wrong answers. The evaluation groups and asset super numbers can be maintained in Customizing and assigned to assets to cover customer requirements for extra fields and groupings. The group asset is a depreciation characteristic; the serial number is a standard field that often ties in with equipment from plant maintenance."
  },
  {
    "chapter_ID": 4,
    "question_ID": 9,
    "question_text": "When you copy an asset, the asset text is copied as well. How can you avoid this?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "By changing the assigned screen layout"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "By changing the assigned tab layout"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "By changing the asset class definitions"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "By creating a dummy reference asset with no text"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "You define which fields get carried over when an asset is used for reference in the screen layout. This isn\u2019t maintained in the tab layout or the asset class. The dummy reference asset isn\u2019t a good solution; it\u2019s more of a hack workaround, and you wouldn\u2019t ever do something like this. Nevertheless, the option probably wouldn\u2019t appear in the certification exam because ambiguous answers are avoided."
  },
  {
    "chapter_ID": 4,
    "question_ID": 10,
    "question_text": "For which kind of asset is the asset main text always prefilled?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Asset super numbers"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Group assets"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Asset subnumbers"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Mass-created assets"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "The asset subnumbers inherit the asset main text from the main asset number, and this can\u2019t be changed."
  },
  {
    "chapter_ID": 4,
    "question_ID": 11,
    "question_text": "You execute a report based on the location and are missing an asset. What might have happened? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "The asset has been fully depreciated."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "The asset was sold to a customer."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "The equipment assigned to the asset was moved."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "The asset was partially scrapped."
      }
    ],
    "answer_correct": [
      "B",
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "When an asset is integrated with the equipment, changes in the equipment can be configured to update the asset. An asset sold will no longer show up in the company code reports. Fully depreciated assets show up in reports because they are still active in the company, and partial scrapping would not deactivate the asset either."
  },
  {
    "chapter_ID": 4,
    "question_ID": 12,
    "question_text": "True or False: The document type maintained in the asset transaction type is proposed but can be changed manually during posting.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. You do maintain a default document type for posting in the asset transaction type. This is defaulted in certain transactions when you don\u2019t enter a separate one manually. An example in which this can be changed is the integrated acquisition posting with supplier, in which document type KR is used instead of transaction type 100 with the default of AA."
  },
  {
    "chapter_ID": 4,
    "question_ID": 13,
    "question_text": "Which transactions lead to asset capitalization? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Purchase order in purchasing"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Valuated goods receipt"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Assets under construction (AUCs) settlement"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Intracompany transfer"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Nonvaluated goods receipt"
      }
    ],
    "answer_correct": [
      "B",
      "C",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "An asset is capitalized when processing a valuated goods receipt. The invoice is used for confirmation/adjustment of the amount. In contrast, with a nonvaluated goods receipt, the asset will be capitalized from the invoice entry. When you settle the line items posted to an AUC to the final assets, those are capitalized. When you post an intracompany asset transfer, the asset is capitalized on the asset value date. The purchase order doesn\u2019t capitalize an asset; that happens either on goods receipt (for a valuated goods receipt) or during invoicing."
  },
  {
    "chapter_ID": 4,
    "question_ID": 14,
    "question_text": "True or False: In both the ledger and accounts approaches, the technical clearing account is posted to by all accounting-principle-specific documents.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. In the accounts approach, only one accounting-principle-specific document posts to the technical clearing account. The rest of the documents post to a different clearing account. If each document posted multiple times to the same account, the account would end up with a balance."
  },
  {
    "chapter_ID": 4,
    "question_ID": 15,
    "question_text": "True or False: When posting an integrated asset sale to a customer, you enter a debit for the customer with posting key 01 and credit for the asset with posting key 75.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. On the credit side, you must enter the revenue account for an asset sale. This is a special account with the field status group for asset sales that allows you to select the asset, dates, and values. You don\u2019t post a credit directly to the asset."
  },
  {
    "chapter_ID": 4,
    "question_ID": 16,
    "question_text": "When making a partial sale, how can you choose to calculate the amount of value reduction for the asset? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "By quantity"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "By posted depreciation"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "By profit (or loss)"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "By retirement amount"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "By percentage"
      }
    ],
    "answer_correct": [
      "A",
      "D",
      "E"
    ],
    "score":0,
    "responded":false,
    "explanation": "When you select a partial sale in the asset retirement popup, you can choose to sell a specific quantity of the asset (if applicable); the value is adjusted based on the amount for sale/total amount fraction. When you select a retirement amount, you directly input the amount of value reduction as a number value. The percentage method simply splits the value based on the percentage entered. You can\u2019t use the other options to determine the value."
  },
  {
    "chapter_ID": 4,
    "question_ID": 17,
    "question_text": "True or False: To post intercompany asset transfers, you maintain the crosscompanycode clearing accounts.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. An intercompany asset transfer works through a different mechanism than financial accounting cross-company postings and doesn\u2019t require separate cross-company account definitions."
  },
  {
    "chapter_ID": 4,
    "question_ID": 18,
    "question_text": "When you post an amount to an asset in the LVA class that is above the threshold for the maximum value, how does the system respond?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "The system produces an error message; for you to post, you must split the asset or post to another asset class."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "You receive a warning message; the asset is posted, but the depreciation key is adjusted to normal depreciation."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "The system posts the value up to the LVA threshold and posts the rest to the generated asset subnumber."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "The system adjusts the asset quantity to the value that keeps the asset below the maximum threshold."
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "When you try to post above the maximum value for an asset created in the LVA class, the system produces an error. You can only post up to the maximum amount for assets in this class. In this case, you would check if you can split the posting or more likely create the asset in a \u201cnormal\u201d asset class. The other options have one thing in common: the system is making a lot of decisions and automations. As a rule, the default system keeps things simple. Too much automation runs into problems when dealing with processes and transactions that might be done very differently depending on the company."
  },
  {
    "chapter_ID": 4,
    "question_ID": 19,
    "question_text": "You have an invoice for transport expenses for your AUCs. These expenses can be capitalized per IFRS but not in the local GAAP. What is the best way to handle this?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Post the invoice to the AUCs and manage settlement per depreciation area."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Post the invoice to a clearing account and clear it per ledger group (capitalize for IFRS, expense for local GAAP)."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Post the invoice to AUCs, and, after settlement, post a partial scrapping for the local depreciation area."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Post the invoice twice: once as an expense to the local ledger group and once more to IFRS."
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "Answer A is the best one because it uses the AUC functionality as it\u2019s intended to be used. You collect the production-related expenses on a single item (as you would use an internal order or a project), and then you settle the amounts. In SAP S/4HANA, the settlement is defined on the depreciation area, so you can manage the capitalization separately per accounting principle. Questions with \u201cwhat is the best\u201d phrasing aren\u2019t part of the certification exam because they are ambiguous, and the questions and answers in the certification exam must be as straightforward as possible."
  },
  {
    "chapter_ID": 4,
    "question_ID": 20,
    "question_text": "The cross-company-code depreciation area is required in which of the following intercompany transfers?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "All intercompany asset transfers"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Transfers with nonmatching depreciation areas"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Transfers between company codes in different countries"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Transfers in which you also change the asset class"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "The cross-company-code depreciation area is used exactly for cases in which the sender has areas that need to be mapped to areas with different codification on the receiver side. You might need it for transfers that are between countries, but you also might not because you might need it for changing asset classes. In questions such as these, you look for the answer that answers the question in all cases."
  },
  {
    "chapter_ID": 4,
    "question_ID": 21,
    "question_text": "An online test run for depreciation is limited to how many assets?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "100"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "1,000"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "10,000"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "No limit"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "This is a pure knowledge question; you either know it or you don\u2019t. When these kinds of questions are in the certification exams, the exam authors try to make them at least cover useful topics. In this case, this is useful knowledge but not essential."
  },
  {
    "chapter_ID": 4,
    "question_ID": 22,
    "question_text": "True or False: You can select to execute the depreciation posting run for a single depreciation area.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. Depreciation can be posted for a single depreciation area or a single accounting principle. For an example of why this might be used, you can close periods per ledger at different times, so this might be required in certain periods."
  },
  {
    "chapter_ID": 4,
    "question_ID": 23,
    "question_text": "True or False: With the depreciation run completing even when assets have errors, you no longer should worry about fixing assets with errors.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. The fact that you can maybe go about closing a period without having successfully posted to an asset doesn\u2019t mean that you shouldn\u2019t fix things as soon as possible. If you leave something problematic as is for too long, the fiscal year change program won\u2019t be able to close a previous fiscal year, and you\u2019ll be locked in without being able to post to the next fiscal year."
  },
  {
    "chapter_ID": 4,
    "question_ID": 24,
    "question_text": "When should fiscal year close for asset accounting be carried out?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Directly before balance carryforward"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Directly after balance carryforward"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "When the previous fiscal year is closed"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "When the last nonspecial period of the previous year is closed"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "The SAP S/4HANA system allows for two open fiscal years for you to manage the closing postings of a fiscal year and start posting in the new fiscal year. You close a fiscal year when you close the books of the previous year, and you\u2019re sure there will be no more postings to assets in the closed fiscal year"
  },
  {
    "chapter_ID": 5,
    "question_ID": 1,
    "question_text": "Why might you define options to have manually allocated item keys for the financial statement version?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "To be able to assign the same key to two multiple objects"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "To be able to enter an explanatory text for the item key"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "To be able to define if you want to summarize at the item level"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "To be able to assign functional areas to the items"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "Manually defining the item keys allows you to enter your own explanatory texts for these."
  },
  {
    "chapter_ID": 5,
    "question_ID": 2,
    "question_text": "True or False: You can define the financial statement version without assigning a specific chart of accounts to it.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. You don\u2019t need to assign a specific chart of accounts to a financial statement version; you must assign a chart of accounts for accounts you define on the item level."
  },
  {
    "chapter_ID": 5,
    "question_ID": 3,
    "question_text": "Which of the following are default items assigned to every financial statement version? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Liabilities and equity"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Profit and loss (P&L) results"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Liquid funds"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Assets"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Noted items"
      }
    ],
    "answer_correct": [
      "A",
      "B",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "The complete list of default items includes the following: \u2013 Assets \u2013 Liabilities and equity \u2013 P&L result \u2013 Net result: loss \u2013 Net result: profit \u2013 Financial statement notes \u2013 Not assigned"
  },
  {
    "chapter_ID": 5,
    "question_ID": 4,
    "question_text": "True or False: You can assign general ledger accounts to any item level in the financial statement version hierarchy.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. You can only assign accounts to the lowest level within a hierarchy node."
  },
  {
    "chapter_ID": 5,
    "question_ID": 5,
    "question_text": "What kind of automated checks are available for the financial statement version? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Check for duplicate item keys"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Check for accounts assigned to one side only"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Check the financial statement notes for non-noted item accounts"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Check for incorrectly assigned accounts"
      }
    ],
    "answer_correct": [
      "B",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "The full list of checks is as follows: \u2013 Accounts assigned to one side only \u2013 Nonassigned accounts \u2013 Incorrectly assigned accounts The system doesn\u2019t need to check for duplicate keys because you can\u2019t create duplicate keys; you get an error before being able to save. Noted items don\u2019t go to the financial statement notes, and they don\u2019t show up at all on the financial statement."
  },
  {
    "chapter_ID": 5,
    "question_ID": 6,
    "question_text": "Which object hierarchies can you maintain with the Manage Global Accounting Hierarchy app? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Cost center"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Internal order"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Segment"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Profit center"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Functional area"
      }
    ],
    "answer_correct": [
      "A",
      "D",
      "E"
    ],
    "score":0,
    "responded":false,
    "explanation": "In this case, even if you don\u2019t know the answer for the specifics of the app, you can guess it based on your knowledge of which objects have hierarchies in SAP S/4HANA. The internal orders and the segments don\u2019t have any hierarchy structure in the system, so they can be excluded as wrong answers."
  },
  {
    "chapter_ID": 5,
    "question_ID": 7,
    "question_text": "In the trial balance report that is based on line items, how many fields can you select to report on?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "More than 100"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Around 50"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Fixed at 10"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "More than 500"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "Around 130 possible line-item fields are available to use for analysis in the standard system."
  },
  {
    "chapter_ID": 5,
    "question_ID": 8,
    "question_text": "How many separate period intervals are there to control open posting periods?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "1"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "2"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "3"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "4"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "There are three intervals: one for normal periods, one for valuations, and one for controlling postings."
  },
  {
    "chapter_ID": 5,
    "question_ID": 9,
    "question_text": "In SAP S/4HANA, on which levels can you maintain open posting periods? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Controlling area"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Segment"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Posting period variant"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Fiscal year variant"
      }
    ],
    "answer_correct": [
      "A",
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "You define the open periods per controlling area in the advanced period definition of controlling. In financial accounting, you use the posting period variant to manage periods for the company codes assigned to it."
  },
  {
    "chapter_ID": 5,
    "question_ID": 10,
    "question_text": "A maximum of how many special/valuation periods can be open at the same time?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "1"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "2"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "4"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "16"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "You can have up to four special/valuation periods in the fiscal year variant, and all can be open at the same time."
  },
  {
    "chapter_ID": 5,
    "question_ID": 11,
    "question_text": "Your company code is assigned to a leading ledger, two additional normal ledgers, and one extension ledger. How many carryforwards must be executed?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "1"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "3"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "4"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "8"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "You perform carryforward separately for each ledger. This definition includes the extension ledgers assigned to a company code."
  },
  {
    "chapter_ID": 5,
    "question_ID": 12,
    "question_text": "What checks can you perform from the Audit Journal app? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Check gaps in journal entry numbering"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Check nonbalancing accounts"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Display journal entry changes"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Check multireferenced invoices"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Display journal entry warnings"
      }
    ],
    "answer_correct": [
      "A",
      "C",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "The full list of available checks includes the following: \u2013 Check gaps in journal entry numbering \u2013 Display journal entry changes \u2013 Check multireferenced invoices \u2013 Display update terminations"
  },
  {
    "chapter_ID": 5,
    "question_ID": 13,
    "question_text": "What is the main differentiating factor of posting a manual accrual document compared to other manual transactions?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Reference field"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Reversal reason"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Document type"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Transaction type"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "The fact that you preemptively assign a reversal reason and the date for reversal in the document header is what makes this posting unique."
  },
  {
    "chapter_ID": 5,
    "question_ID": 14,
    "question_text": "True or False: You can adjust a recurring entry before each run to account for price updates on purchases.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. Recurring entries are posted in the same way, meaning you can\u2019t use different accounts, amounts, or anything."
  },
  {
    "chapter_ID": 5,
    "question_ID": 15,
    "question_text": "How can you select to post accrual amounts when customizing the accrual engine? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Post delta amounts"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Post foreign currencies"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Post closed periods"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Post full amounts"
      }
    ],
    "answer_correct": [
      "A",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "You can select between full amounts, for which the system inverts the posted amount and reposts the complete amount, and delta posting, in which the system only posts the difference calculated for the period. You can never post to a closed period, and foreign currencies will be updated according to the ledger configurations, not the accrual management configuration."
  },
  {
    "chapter_ID": 5,
    "question_ID": 16,
    "question_text": "Which process is automated with purchase order accruals, compared to manual accruals with the accrual engine?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Start accrual run"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Create accrual object"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Create opening posting"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Breakdown accrual amount"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "The creation of the accrual object is automated; specifically, an additional accrual object is created for each (relevant) purchase order. You must schedule or start the accrual run for both purchase order and manual accruals. Purchase order accruals have no opening posting; only periodic postings are required. The accrual amount is broken down automatically irrespective of the type of accrual."
  },
  {
    "chapter_ID": 5,
    "question_ID": 17,
    "question_text": "To which object do you assign valuation methods?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Valuation procedure"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Account assignment"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Accounting principle"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Valuation area"
      }
    ],
    "answer_correct": [
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "You assign the valuation procedure to the valuation method. After defining the valuation method, you assign it to the valuation area. You assign the valuation area to the accounting principle."
  },
  {
    "chapter_ID": 5,
    "question_ID": 18,
    "question_text": "You use the lowest-value valuation method with active delta logic. Your open item was posted at 200 units of foreign currency, which originally translated to 100 units of local currency, and in the previous period it was 98 units. In the preceding foreign currency valuation run, the program posted a value of \u20131 currency units. What was the value in local currency units at the time of valuation?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "99"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "97"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "101"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "98"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "The valuation currently stands at 98, having a \u20132 amount from the initial valuation. Because the delta logic is enabled, the entry isn\u2019t reversed. When \u20131 is posted, this is on top of the \u20132 already posted; therefore, in total, it\u2019s 100 \u2013 3 = 97."
  },
  {
    "chapter_ID": 5,
    "question_ID": 19,
    "question_text": "True or False: You can post a simulation run for the leading ledger to analyze the effects of the currency valuation on your P&L standings.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. Simulation runs are executed not for standard ledgers but for extension ledgers. The extension ledger then must be defined as a simulation extension ledger."
  },
  {
    "chapter_ID": 5,
    "question_ID": 20,
    "question_text": "Flat-rate individual value adjustments are valid for which types of accounts?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Assets"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Supplier"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Customer"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Materials"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "Only customers can be included in these valuations."
  },
  {
    "chapter_ID": 5,
    "question_ID": 21,
    "question_text": "What are the reasons to perform regrouping of receivables and payables? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "To display receivables and payables separately"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "To group payables and receivables based on the due dates"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "To align amounts on accounts with changed reconciliation accounts"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "To simplify and speed up dunning selection processing"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "To prioritize balance confirmation printing by postal code"
      }
    ],
    "answer_correct": [
      "A",
      "B",
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "Regrouping has nothing to do with dunning, nor with balance confirmations."
  },
  {
    "chapter_ID": 5,
    "question_ID": 22,
    "question_text": "Which type of stock is best suited to a moving average price (MAP) valuation?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Raw materials"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Work in process (WIP)"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Finished goods"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Third party"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "Raw materials are usually brought in, so the MAP valuation is generally the best fit for these materials."
  },
  {
    "chapter_ID": 5,
    "question_ID": 23,
    "question_text": "For which of the following tasks must you always indicate manual task completion?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Programs"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Transactions"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Notes"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Flow definitions"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "The notes task type is essentially for setting reminders and providing information, so there is no posting triggered automatically by the system for this kind of task."
  },
  {
    "chapter_ID": 5,
    "question_ID": 24,
    "question_text": "rue or False: Each task in the SAP S/4HANA Financial Closing cockpit can have exactly one prerequisite task assigned to it.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. You can assign multiple tasks as prerequisites, including an entire task folder"
  },
  {
    "chapter_ID": 6,
    "question_ID": 1,
    "question_text": "When adding a bank to the bank directory, which field is required?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Company code"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Opening date"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Currency"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Country"
      }
    ],
    "answer_correct": [
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "You must define the country, bank code, and bank name when defining a new bank. The details for company code are maintained on the house bank level and bank account level. The opening date and currency are maintained per bank account."
  },
  {
    "chapter_ID": 6,
    "question_ID": 2,
    "question_text": "On which level do you maintain the bank directory?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Bank area"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Company code"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Client"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Profit center"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "Banks are defined on the client level and are assigned to a country. Because you may have many company codes, customers, and suppliers using the same banks, it makes sense that these are defined at the highest level. If you pay attention, part of this question can be answered by the previous question. When writing items for the certification, we try to not have cases like these, where an answer for one question is implied by a different question."
  },
  {
    "chapter_ID": 6,
    "question_ID": 3,
    "question_text": "What is defined in the bank account connectivity path settings?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Payment approvers for workflows"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Business partner assigned to the bank"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "House bank account ID details"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Relevant profit center and segment"
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "The connectivity path is where you define the connection between a bank account and the house bank. The business partner is defined in the bank details, the payment approvers are defined in the bank account signatory details, and the profit center and segment can be assigned in the account additional details."
  },
  {
    "chapter_ID": 6,
    "question_ID": 4,
    "question_text": "When creating a bank as a business partner, what roles are assigned to the business partner by default? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Customer"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Supplier"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "General"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Financial Services"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Bank"
      }
    ],
    "answer_correct": [
      "C",
      "D",
      "E"
    ],
    "score":0,
    "responded":false,
    "explanation": "The correct answers are the bank role, the financial services role, and the general role. The general role is always assigned; you can\u2019t create a business partner without it. For the others, remember that we can\u2019t create \u201cfake\u201d answer options, so the obvious ones are the actual correct ones here. When creating a bank, you don\u2019t assign a customer or supplier reconciliation account at any point, so the system wouldn\u2019t be able to create the customer or supplier role in the business partner master record."
  },
  {
    "chapter_ID": 6,
    "question_ID": 5,
    "question_text": "What are the differences between holding and parking documents? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "The held document doesn\u2019t have a document number assigned."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "The held document can be incomplete."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "The parked document can trigger workflow processes."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "The parked document is considered for financial statements."
      }
    ],
    "answer_correct": [
      "A",
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "Held documents don\u2019t receive a document number (though the user assigns an identifier to be able to retrieve them). Only document parking is integrated with SAP Business Workflow. On the other hand, both parked and held documents can be saved incomplete, and neither held nor parked documents are included in financial statements."
  },
  {
    "chapter_ID": 6,
    "question_ID": 6,
    "question_text": "On which level is the document parking workflow variant assigned?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Company"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Company code"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Business partner"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Profit center"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "You assign the workflow variant to each company code. You can view the assignment also in the global settings for the company code (Transaction OBY6 in SAP Easy Access)."
  },
  {
    "chapter_ID": 6,
    "question_ID": 7,
    "question_text": "How can you differentiate the document parking workflow process on the business partner level?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Assign different workflow release groups"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Assign different payment methods"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Assign different business partner groupings"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Assign different responsible organization units"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "You assign the workflow release group in the company code details screens of the business partner roles for financial accounting customers and suppliers. This way you can control the release strategy separately for different customers and suppliers in your system."
  },
  {
    "chapter_ID": 6,
    "question_ID": 8,
    "question_text": "True or False: Subworkflows define the number of release levels in the document parking workflow process.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. The subworkflow is where you define the special characteristics of the workflow, including the number of release levels. You can use the subworkflow, for example, to differentiate the approvers based on different amounts. "
  },
  {
    "chapter_ID": 6,
    "question_ID": 9,
    "question_text": "What options does a processor have for a general journal entry submitted for verification? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Park"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Approve"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Delete"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Reject"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Suspend"
      }
    ],
    "answer_correct": [
      "B",
      "D",
      "E"
    ],
    "score":0,
    "responded":false,
    "explanation": "As a processor, you can approve or reject the document entry, so this is a oneout- of-three question in essence. The correct option out of the others is suspend; this is the term selected by SAP to put a journal entry in waiting. Delete is a very rare function in SAP S/4HANA when it comes to documents, by design. On the other hand, park means basically the same as suspend, but it\u2019s not the correct term here."
  },
  {
    "chapter_ID": 6,
    "question_ID": 10,
    "question_text": "Which values can you define as prerequisites for triggering the general journal entry verification workflow? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Account group"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Target company code"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Cost center"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Source company code"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Profit center"
      }
    ],
    "answer_correct": [
      "A",
      "C",
      "D"
    ],
    "score":0,
    "responded":false,
    "explanation": "The correct answers are account group, source company code, and cost center. In addition, you can also use the threshold value. In many cases in which there are more than three correct options, the easiest ones aren\u2019t chosen to be included in the certification (here, value is a very obvious prerequisite). The profit center option is a good distractor because it \u201cmirrors\u201d the cost center, which is a correct answer. The target company code just plays with the source/ target option; it isn\u2019t as strong a distractor, though."
  },
  {
    "chapter_ID": 6,
    "question_ID": 11,
    "question_text": "Why would you choose to implement the document parking workflow for journal entry verification, compared to the general journal entry verification process?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "You do not use SAP Fiori."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "You need to define two-level verification."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "You\u2019re running SAP S/4HANA 1610."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "You don\u2019t know how to configure the new functionality."
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "The general journal entry verification functionality requires SAP Fiori. Although you should really never have to work on an SAP S/4HANA system without SAP Fiori, there are a number of customers who have chosen this path. The delivered workflow process is a single step, but you can create your own processes with two verification levels."
  },
  {
    "chapter_ID": 6,
    "question_ID": 12,
    "question_text": "What are the basic definitions you make for validations? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Prerequisite"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Check"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Rule"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Set"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Message"
      }
    ],
    "answer_correct": [
      "A",
      "B",
      "E"
    ],
    "score":0,
    "responded":false,
    "explanation": "The prerequisite must be fulfilled for the system to perform the checks if the validation is true. The message defined is raised if the check isn\u2019t confirmed. Rules and sets can be used to define the prerequisite and check details."
  },
  {
    "chapter_ID": 6,
    "question_ID": 13,
    "question_text": "At which levels can validations and substitutions for financial accounting be defined? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Line item"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "System data"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Universal Journal"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Complete document"
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Header"
      }
    ],
    "answer_correct": [
      "A",
      "D",
      "E"
    ],
    "score":0,
    "responded":false,
    "explanation": "Each validation and substitution is defined for a specific callup point. The same validation/substitution can\u2019t be used in two different callup points. For financial accounting, you define them at the level of the Document Header, Line Item, or Complete Document."
  },
  {
    "chapter_ID": 6,
    "question_ID": 14,
    "question_text": "True or False: A single substitution step can be defined to substitute multiple fields in a document.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "True. Although most times a step fulfills a single substitution prerequisite, you can change multiple fields with a single step. This way, you can trigger changes even if there aren\u2019t direct field dependencies defined through assignments in the master records."
  },
  {
    "chapter_ID": 6,
    "question_ID": 15,
    "question_text": "True or False: Multisets can be created if they combine data from basic sets that all group the same characteristic.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. Multisets combine any kind of basic sets independent of the underlying characteristics. The statement here fits the description of a single set, which indeed combines basic sets defined for the same attribute."
  },
  {
    "chapter_ID": 6,
    "question_ID": 16,
    "question_text": "What is the main motivation for creating rules for substitutions and validations?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Simpler definition tools"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Reuse in multiple steps"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Not bound by Boolean logic"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Additional fields available"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "You can use rules in multiple steps of a validation or substitution, so you can speed up the time required to create them by reusing them. The tools, fields, and logic used are the same as those available when maintaining prerequisite and check data."
  },
  {
    "chapter_ID": 6,
    "question_ID": 17,
    "question_text": "What are the steps in the Customizing of data archiving? (There are three correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Define archiving storage locations."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Perform cross-object Customizing."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Define table partitions."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Activate data aging objects."
      },
      {
        "answer_ID": "E",
        "selected":false,
        "answer_text": "Perform object-specific Customizing."
      }
    ],
    "answer_correct": [
      "A",
      "B",
      "E"
    ],
    "score":0,
    "responded":false,
    "explanation": "The sequence of Customizing steps is to define the archiving storage locations, perform cross-object Customizing, and finally customize the object-specific settings. The table partitioning and aging object activation steps are part of the activation process for data aging."
  },
  {
    "chapter_ID": 6,
    "question_ID": 18,
    "question_text": "What is a reason for performing data archiving?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "To have fewer downtime cycles"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "To control hardware requirements"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "To protect data from being lost"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "To back up data on a separate drive"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "By performing data archiving, you can maintain stable hardware requirements because the database is kept at a constant size. With data archiving, you can have shorter downtime cycles, but not fewer ones. Data is never lost, data archiving has no effect on this, and data archiving isn\u2019t a data backup process."
  },
  {
    "chapter_ID": 6,
    "question_ID": 19,
    "question_text": "True or False: If you can delete a general ledger account for a company code, you can also remove it from the chart of accounts.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. You need to delete a general ledger account from all company codes to be able to delete it from the chart of accounts as well. In general, deleting anything in the SAP S/4HANA system, which acts as a system of record, is only performed after many dependency and consistency checks."
  },
  {
    "chapter_ID": 6,
    "question_ID": 20,
    "question_text": "True or False: You execute data aging separately for each data aging object.",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "True"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "False"
      }
    ],
    "answer_correct": [
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "False. You create object groups that can have multiple aging objects combined for sequential processing. This makes it easier and more efficient to administrate the aging run, and you can be sure you\u2019re not forgetting aging objects."
  },
  {
    "chapter_ID": 6,
    "question_ID": 21,
    "question_text": "Which of the following is a difference between data aging and data archiving?",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Data aging allows you to retrieve the aged data for analysis."
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Data aging frees up memory space."
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Data archiving removes data from the database."
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Data archiving has advanced logging capabilities."
      }
    ],
    "answer_correct": [
      "C"
    ],
    "score":0,
    "responded":false,
    "explanation": "The archiving process physically removes data from the database, whereas data aging moves it to a historical partition. In both cases, the data is retrievable (albeit not as easily or swiftly for the archived data), both have a positive effect on the used memory, and both offer powerful log tools to check the results of the runs."
  },
  {
    "chapter_ID": 6,
    "question_ID": 22,
    "question_text": "On which levels can you maintain useful life for documents of a company code in the context of data aging? (There are two correct answers.)",
    "answer_options": [
      {
        "answer_ID": "A",
        "selected":false,
        "answer_text": "Document type"
      },
      {
        "answer_ID": "B",
        "selected":false,
        "answer_text": "Account type"
      },
      {
        "answer_ID": "C",
        "selected":false,
        "answer_text": "Number range interval"
      },
      {
        "answer_ID": "D",
        "selected":false,
        "answer_text": "Profit center"
      }
    ],
    "answer_correct": [
      "A",
      "B"
    ],
    "score":0,
    "responded":false,
    "explanation": "The aging Customizing allows you to control the useful life per company code and account type, so you can define a different retention period for general ledger accounting documents compared to, for example, asset accounting documents. You can also configure the useful life per company code and document type, so again, document type SA for general ledger postings can have a different retention period for real-time analysis than the AA document type for assets."
  }
]