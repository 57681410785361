import React from 'react'
import QuestionOverview from './subSection/QuestionOverview';

function ExamsQuestionOverView() {
    return (
<>
        <h2 className="text-gray-800 font-bold text-4xl mb-2">Questions</h2>
            <QuestionOverview/>
</>
    );
}

export default ExamsQuestionOverView;
