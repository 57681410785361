import React from 'react'

function QuestionCard(props) {
    const {chapter_ID,question_ID,question_text,answer_options,answer,explanation} = props.question
    const handleCheckBoxClick = (event) => {
        //console.log(event.target)
    }
    return (
        <div className="bg-white shadow-lg rounded-lg overflow-hidden my-4 w-4/5 mx-auto">
            <div className="px-4 py-2">
                <h2 className="text-gray-800 font-bold text-2xl mb-2">C{chapter_ID} - Q{question_ID}: {question_text}</h2>
                {answer_options.map((res,index)=>(
                    <div key={index} className="flex items-center mb-2">
                        <input 
                            type="checkbox" 
                            id={`c${chapter_ID}-q${question_ID}-r${res.answer_ID}`} 
                            name={`c${chapter_ID}-q${question_ID}-r${res.answer_ID}`} 
                            value={`c${chapter_ID}-q${question_ID}-r${res.answer_ID}`} 
                            className="mr-2"
                            onClick={handleCheckBoxClick}
                        />
                        <label htmlFor={`c${chapter_ID}-q${question_ID}-r${res.answer_ID}`} className="text-gray-700 text-base">{res.answer_ID}: {res.answer_text}</label>
                    </div>
                ))}
                <div className="px-4 py-2 bg-gray-200">
                    <p className="text-gray-700 text-base mb-2">{explanation}</p>
                </div>
            </div>
        </div>
    );
  }
  
  export default QuestionCard;
  