export const awaiting_Data=[
  {
    "chapter_ID": 1,
    "question_ID": 1,
    "question_text": "AWAITING DATA LOAD",
    "answer_options": [
      {
        "answer_ID": "A",
        "answer_text": "WAITING"
      },
      {
        "answer_ID": "B",
        "answer_text": "WAITING"
      }
    ],
    "answer_correct": [
      "A"
    ],
    "score":0,
    "responded":false,
    "explanation": "Data has not laoded yet"
  }
]