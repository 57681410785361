import { Link } from 'react-router-dom'

function Header(props) {
  const handleLogOut=(event) =>{
    event.preventDefault()
    props.handleLogout()
    console.log('Logout clicked')
  }

  return (
    <header className="bg-gray-800 p-4 flex justify-between items-center">
    <div className="text-white font-bold text-xl">My App</div>
    <nav>
      <ul className="flex space-x-4">
        <li>
          <Link to="/" className="text-white hover:text-gray-400">
            Start Exam
          </Link>
        </li>
        <li>
          <Link to="/examQuestions" className="text-white hover:text-gray-400">
              View Exams
          </Link>
        </li>
        <li>
          <Link to="/results" className="text-white hover:text-gray-400">
              Results
          </Link>
        </li>
        <li>
          <button className="text-white hover:text-gray-400" onClick={handleLogOut}>
                Logout
          </button>
        </li>
      </ul>
    </nav>
  </header>
  );
}
  
  export default Header;
  