import React,{useState,useEffect}from 'react'
import { useLocation } from 'react-router-dom';

function ExamResults() {
    const location = useLocation();

    const [currentPage, setCurrentPage] = useState(1);
    const [questionsPerPage] = useState(10);
    const [questions,setQuestions]=useState(location.state.questions)
    const [score,setScore]=useState(location.state.score)

    const indexOfLastQuestion = currentPage * questionsPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    const examName = location.pathname.split('/exam/')[1].split('/results')[0]

    const currentQuestions = questions.slice(
      indexOfFirstQuestion,
      indexOfLastQuestion
    );
    useEffect(()=>{
        //console.log(questions[0].answer_options)
        console.log(questions)
        console.log(score)
    },[])

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(questions.length / questionsPerPage); i++) {
      pageNumbers.push(i);
    }
  
    const handlePageClick = (event) => {
      setCurrentPage(Number(event.target.id));
      window.scrollTo({
        top:0,
        behavior: 'smooth'
      })
    };

    return (
        <>
            <div className='flex flex-col h-full'>
                <div className="flex flex-col items-center justify-center h-40 bg-gray-100">
                    <h1 className="text-gray-800 font-bold text-4xl mb-2">{examName}</h1>
                    <p className="text-gray-800 font-bold text-2xl mr-4">Score: {score} / {questions.length}</p>
                    <div className="flex flex-wrap justify-center">
                        {questions.map((question, index) => (
                            <button className={` hover:bg-gray-500 w-10 h-10 m-2 flex justify-center items-center rounded-lg ${question.score === 1 ? 'bg-green-500':'bg-red-500'}`}> {index+1} </button>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center h-3/5 p-5">
                    <div className="bg-gray-200 p-4 rounded-lg w-2/3">
                    {questions.map((question,index)=>(
                        <div className="bg-white rounded-lg shadow-lg p-6 h-full w-full relative mb-5">
                            <h2 className="text-xl font-bold mb-4">{index+1} - Chapter: {question.chapter_ID} - Question: {question.question_ID}</h2>
                            <p className="text-gray-700 mb-4">{question.question_text}</p>
                            {question.answer_options.map((anwser,index)=>(
                                <li key={anwser.answer_ID}>
                                    <label className="inline-flex items-center mt-3">
                                        <input type="checkbox" className="form_checkboxes h-5 w-5 text-gray-600" checked={anwser.selected}  name="answer" value={index} disabled="true" />
                                            <div>
                                                <span className={`ml-2
                                                    ${question.responded === true & question.answer_correct.includes(anwser.answer_ID) === true ? 'font-bold text-green-700':''}
                                                    ${question.responded === true & question.answer_correct.includes(anwser.answer_ID) === false ? 'font-bold text-red-700':''}
                                                    ${question.responded === false & question.answer_correct.includes(anwser.answer_ID) === true ? 'font-bold text-red-700':''}`}>{anwser.answer_ID}: {anwser.answer_text}</span>
                                            </div>
                                    </label>
                                </li>
                            ))}                        
                        </div>
                    ))}
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center h-1/5 p-5">
                    {pageNumbers.map((number) => (
                    <button
                        key={number}
                        id={number}
                        onClick={handlePageClick}
                        className={`bg-gray-200 text-gray-700 font-semibold mx-1 px-4 py-2 my-3 rounded-md ${
                        currentPage === number ? "bg-gray-400" : ""
                        }`}
                    >
                        {number}
                    </button>
                    ))}
                </div>
            </div>
        </>
    );
}

export default ExamResults;