import React,{useState}from 'react'
import { SAP_FI } from "../Data/Questions/SAP_FI";
import { current_quiz } from '../Data/current_quiz.js';
import { sap_fi_cloud } from '../Data/Questions/sap_fi_cloud.js';
import { useNavigate   } from 'react-router-dom';

function ExamSelection() {
    const navigate = useNavigate()

    const [error, setError]=useState("")
    const [amount, setAmount] = useState(20);

    const SAP_FI_Clicked=(event)=>{
        event.preventDefault()
        setError("")    
        selectRandomQuestions(SAP_FI)
        navigate('/exam/sap_fi',{state:{'amount':amount}})
    }

    const selectRandomQuestions=(base_questions)=>{
        if (amount<5){
            setError("select at least 5 questions")
            return
        }

        if (amount>Object.keys(base_questions).length)
            setAmount(Object.keys(base_questions).length) 

        while (current_quiz.length < amount) {
            const randomIndex = Math.floor(Math.random() * base_questions.length);
            const randomQuestion = base_questions[randomIndex];
            if (!current_quiz.includes(randomQuestion)) {
                current_quiz.push(randomQuestion);
            }
        } 
        
    }
    const SAP_FI_CLOUD_Clicked=(event)=>{
        event.preventDefault()
        setError("")
        selectRandomQuestions(sap_fi_cloud)
        navigate('/exam/sap_fi_cloud',{state:{'amount':amount}})
    }

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <div className="w-full max-w-md">
                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <h2 className="text-gray-800 font-bold text-4xl mb-2">Select an exam</h2>
                    <div className="mb-4">
                        <label htmlFor="amount" className="block mb-2 font-medium">
                            Number of Questions:
                        </label>
                        <input type="number" id="amount" name="amount" className="border-gray-400 border-2 rounded-md p-2 w-1/2 mb-4" value={amount} onChange={(event) => setAmount(event.target.value)}/>
                    </div>
                    <h2 className="text-red-500 font-bold text-2xl mb-2">{error}</h2>
                    <div className="flex items-center justify-between">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={SAP_FI_Clicked} > SAP_FI </button>
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={SAP_FI_CLOUD_Clicked}> SAP_FI_CLOUD </button>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default ExamSelection;